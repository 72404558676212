
#imgcat{
    width: 310px;
    height: 310px;
    object-fit: cover;
    border-color: orange;
    border-width: 3px;
    border-style: solid;
}
#igmdiv{
   margin-right: 70px;
   margin-left: 70px;
   
}

#pas{
    font-weight: bold;
    width: 280px;
    height: 32px;
    text-align: center;
}
#imgcat:hover{
   background: aquamarine;
}

.image-container {
    position: relative;
    display: inline-block;
    
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:  rgb(33, 24, 24); /* Initially transparent */
    opacity: 0; /* Initially invisible */
    transition: opacity 0.4s ease;
    border-radius: 200px; /* Smooth transition effect */
    transition: 0.4s;
  }
  
  .image-container:hover .overlay {
    background-color: rgba(52, 50, 50, 0.5); /* Dark transparent layer */
    opacity: 1;
    transition: 0.4s; /* Make the overlay visible on hover */
  }
  
  /* Additional styling for the image */
  .img-thumbnail {
    display: block;
    width: 100%;
    height: auto;
  }
  
  /* You can adjust the rest of the styling as needed */
  @media (max-width: 500px) {
    #imgcat{
        height: 230px;
       width: 230px;
       
    }
    #igmdiv{
        margin-right:0px;
        margin-left: 0px;
     }

     #pas{
      font-weight: bold;
      width: 250px;
      height: 32px;
      text-align: center;
  }
  }

@media (max-width: 1200px) {
    #imgcat{
        height: 230px;
       width: 230px;
       
    }
    #igmdiv{
        margin-right:0px;
        margin-left: 0px;
     }

     #pas{
      font-weight: bold;
      width: 250px;
      height: 32px;
      
  }
  }
 