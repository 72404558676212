
#imgcat{
    width: 280px;
    height: 280px;
    object-fit: cover; border-radius: 10px;
   
}
#igmdiv{
   margin-right: 70px;
   margin-left: 70px;
   margin-top: 100px;
   margin-bottom: 60px;
}

#pa{
    font-weight: bold;
    
}
#imgcat:hover{
   background: aquamarine;
}

.image-containe {
    position: relative;
    display: inline-block;
    background-color: orange;
    transition: 0.4s;
    margin-bottom: 20px; border-radius: 10px;
    
  }

  .image-containe:hover{
    cursor: pointer;
    box-shadow: 0px 12px 30px 0px rgb(38, 37, 37);
    transform: scale(1.1,1.1);
    transition: 0.4s;
    border-radius: 8px;
    
}
  
  
  
  
 
  
  /* You can adjust the rest of the styling as needed */
  

@media (max-width: 1200px) {
    #imgcat{
        height: 230px;
       width: 230px;
    }
    #igmdiv{
        margin-right:0px;
        margin-left: 0px;
     }
     .image-containe{
        width: 230px;
     }
     #pa{
        font-weight: bold;
        width: 230px;
    }
  }
 