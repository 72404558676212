.header{
    min-height: 100vh;
   
}
.header__content{
    min-height: calc(100vh - 106px);
    flex-direction: column;
}
.header__title{
    font-size: 4.1rem;
    line-height: 1.2;
}
.header__content .para__text{
    font-size:medium;
    font-weight:600;
    padding: 3.6rem 0;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}


@media(min-width: 992px){
    .header__content{
        flex-direction: row;
    }
    .header__content--right{
        text-align: left;
        width: 50%;
    }
    .header__content--left{
        width: 50%;
    }
    .header__content--right .para__text{
        margin-left: 0;
    }
}

.btn__blue {
    background-color: rgb(0, 213, 255);
    color: white; 
    border-radius: 5px; 
    font-style: italic; 
    text-decoration: none; 
    padding: 10px 20px; 
    transition: background-color 0.3s, color 0.3s;
    font-size: 20px;
  }
  
  
  .btn__blue:hover {
    background-color: #001e3d; 
    color: white; 
  }
  
 
  .btn__blue:active, .btn__blue:focus {
    background-color: #0056b3; 
    color: white; 
  }
  

  /* Header.css */

body {
    margin: 0;
    padding: 0;
    background-image: url('bg1.jpg'); /* Replace 'path/to/your/background-image.jpg' with the actual path to your image */
    background-size: cover; /* Ensures the background image covers the entire viewport */
    background-position: center; /* Centers the background image */
    background-repeat: no-repeat; /* Prevents the background image from repeating */
  }
  
  .header {
    /* Your existing styles for the header */
    /* ... */
  }
  
  .container2 {
    /* Your existing styles for the container2 */
    /* ... */
  }
  
  /* Add more styles as needed */
  