

footer{
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    background-color: #000000;
    padding: 60px 10%;
}

ul{
    list-style: none;
}

.footer-col{
    width: 25%;
}

.footer-col h4{
    position: relative;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 22px;
    color: orange;
    text-transform: capitalize;
}

.footer-col h4::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    background-color: #27c0ac;
    height: 2px;
    width: 40px;
}

ul li{
    margin-bottom: 8px;
    margin-left: -30px;
}

ul li a{
    display: block;
    font-size: 19px;
    text-transform: capitalize;
    color: #bdb6b6;
    text-decoration: none;
    transition: 0.4s;
}

ul li a:hover{
    color: white;
    padding-left: 2px;
}

.links a{
    display: inline-block;
    height: 44px;
    width: 44px;
    color: white;
    background-color: #0de2f1;
    margin: 0 8px 8px 0;
    text-align: center;
    line-height: 44px;
    border-radius: 50%;
    transition: 0.4s;
}

.links a:hover{
    color: #0de2f1;
    background-color: white;
}

@media(max-width: 740px){
    .footer-col{
        width: 50%;
        margin-bottom: 30px;
        text-align: center;
        margin-left: auto;
    }

    .footer-col h4::before{
        all: unset;
    }
}

@media(max-width: 740px){
    ul li{
        margin-left: -30px;
    }
}

@media(max-width: 555px){
    .footer-col{
        width: 100%;
    }
}