/* AboutUs.css */

.about {
  
    padding: 40px 0;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .headAbout {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .detailsAbout {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
  }
  